<template>
  <v-card class="mt-3" flat>
    <v-card-title class="mb-6">
      <v-text-field
        v-model="search"
        class="pt-0 mt-0"
        width="300"
        hide-details
        single-line=""
        placeholder="Busca (nome do produto)"
        append-icon="search"
        outlined
      />
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row justify="end" class="mt-n12">
        <v-col cols="auto">
          <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-list-products-franchise" />
        </v-col>
      </v-row>
      <v-data-table
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
        :server-items-length="maxRecords"
        :options.sync="options"
        :headers="computedHeaders"
        :items="activeProductsData"
        :search="search"
      >
        <template v-slot:[`item.product_type`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ getProductFromType(item.product_type).name }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon>{{ item.active ? 'Ativo' : 'Inativo' }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.unit_value`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ mountUnitValue(item) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.temporary_churn`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              <v-icon class="mr-2" small :color="item.temporary_churn ? 'error' : 'success'">{{
                item.temporary_churn ? 'mdi-alert' : 'mdi-check-circle'
              }}</v-icon
              >{{ item.temporary_churn ? 'Sim' : 'Não' }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ item.sale_date ? formatDate(item.sale_date) : formatDate(item.created_at) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ formatDate(item.updated_at) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.total_mrr`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ parseCurrencyDefault(item.total_mrr) }}
            </v-layout>
          </v-flex>
        </template>
        <template v-slot:[`item.billing_date`]="{ item }">
          <v-flex>
            <v-layout justify-center align-center>
              {{ item.billing_date ? formatDate(item.billing_date) : '-' }}
            </v-layout>
          </v-flex>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT } from '@/modules/franchises/graphql'
import { parseCurrency, headers, productsInformation } from '@/mixin'
import { tableHeaders } from '@/constants/sales'
import { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'

export default {
  components: {
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable')
  },
  mixins: [parseCurrency, headers, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    loading: false,
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    exporting: false,
    exportData: [],
    headers: [],
    dialogSetDate: false
  }),
  apollo: {
    availableProducts: {
      query: QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { franchise_id: this.$route.params.id },
          { filters: {} },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ franchiseAvailableProducts: { count, data } }) {
        this.maxRecords = count
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  mounted() {
    if (this.headers.length < 1) this.headers = cloneDeep(tableHeaders.avaliableProducts)
  },
  methods: {
    openDialogSetDate() {
      this.dialogSetDate = true
    },
    getDefaultHeadersOptions() {
      return cloneDeep(tableHeaders.avaliableProducts)
    },
    mountUnitValue(sale) {
      const unitVal = sale.unit_value ? sale.unit_value : sale.total_mrr / sale.slots
      return sale.product_type === 'sms' ? this.parseCurrencySms(unitVal) : this.parseCurrencyDefault(unitVal)
    },
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('dd/MM/yyyy')
    }
  }
}
</script>
